/**  
* This file used for internal field and configuration
*/
var left_menu=[
{
  "name": "documents",
  "display": "Documents",
  "object_holder":"Documents",
  "icon": "fa fa-file-o",
  "url": "list/documents",
  "active_route":["create_documents"],
  "group_under":"Entities"
},
];


var configs={  


  "documents":{
    "role_module":"Documents",
    "module": "documents",
    "display": "Documents",
    "object_holder":"Documents",
    "singular_name":"Document",
    "post_url":"Documents/CRUD",
    "list_url":"Documents/all_list",
    "get_by_id_url":"Documents/",
    "id_field":"DocumentId",
    "actions":["download","view"],
    "list_custom_count":false,
    "sort_fields":[{name:"DocumentTitle",display:"Title(A-Z)"},{name:"DocumentTitle",display:"Title(Z-A)",descending:true},
    {name:"DocumentTypeId",display:"Type(A-Z)"},{name:"DocumentTypeId",display:"Type(Z-A)",descending:true},{name:"ValidDateFrom",display:"Date(0-9)"},{name:"ValidDateFrom",display:"Date(9-0)",descending:true}],

    "head_sort_fields":[{name:"DocumentTitle",display:"Title",width:20},
    {name:"Category",display:"Category",width:20},{name:"DocumentTypeName",display:"Type",width:15},{name:"ValidDateFrom",display:"Validity",width:15},{name:"Place",display:"Place",width:15}],

    "form_fields":[],
    loc_field:{name:'LocationId',display:'Location','type':'fetcher',load_from_api:"Locations",read_from_api:"Location/",allowed_values:[],ref_field:'LocationId',display_field:'LocationName'},
    type_field:{name:'DocumentTypeId',display:'Document Type','type':'fetcher',load_from_api:"DocumentTypes",read_from_api:"DocumentType/",allowed_values:[],ref_field:'DocumentTypeId',display_field:'DocumentTypeName'},
    equipmenttype_field:{name:'EquipmentTypeId',display:'Equipment Type','type':'fetcher',load_from_api:"EquipmentTypes",read_from_api:"EquipmentType/",allowed_values:[],ref_field:'EquipmentTypeId',display_field:'EquipmentTypeName'},    
    organization_field:{name:'OrganizationId',display:'Organization','type':'fetcher',load_from_api:"Organization",read_from_api:"Organization/",allowed_values:[],ref_field:'OrganizationId',display_field:'OrganizationName'},
    individual_field:{name:'ittfid',display:'Individual','type':'fetcher',"allowed_values":[],load_from_api:"Individuals",read_from_api:"Individuals/Id/#ref",ref_field:'ittfid',display_field:'IndividualName',list_display_field:"Passport_fullname"},
    event_field:{name:'EventId',display:'Event','type':'fetcher',load_from_api:"Events",read_from_api:"Event/",allowed_values:[],ref_field:'EventId',display_field:"EventName",list_display_field:"EventName"},
    brand_field:{name:'BrandId',display:'Brand','type':'fetcher',load_from_api:"Brands",read_from_api:"Brands/",allowed_values:[],ref_field:'BrandId',display_field:'BrandName'},
    equipment_ball_field:{name:'EquipmentBallId',display:'Ball','type':'fetcher',load_from_api:"Equipment_Balls",read_from_api:"Equipment_Ball/",allowed_values:[],ref_field:'EquipmentId',display_field:'EquipmentName',list_display_field:"BallName"},
    equipment_table_field:{name:'EquipmentTableId',display:'Table','type':'fetcher',load_from_api:"Equipment_Tables",read_from_api:"Equipment_Table/",allowed_values:[],ref_field:'EquipmentId',display_field:'EquipmentName',list_display_field:"TableName"},
    equipment_net_field:{name:'EquipmentNetId',display:'Net','type':'fetcher',load_from_api:"Equipment_Nets",read_from_api:"Equipment_Net/",allowed_values:[],ref_field:'EquipmentId',display_field:'EquipmentName',list_display_field:"NetName"},
    equipment_floor_field:{name:'EquipmentFloorId',display:'Floor','type':'fetcher',load_from_api:"Equipment_Floors",read_from_api:"Equipment_Floor/",allowed_values:[],ref_field:'EquipmentId',display_field:'EquipmentName',list_display_field:"FloorName"},
    equipment_larc_field:{name:'EquipmentLARCId',display:'LARC','type':'fetcher',load_from_api:"Equipment_LARC",read_from_api:"Equipment_LARC/",allowed_values:[],ref_field:'EquipmentId',display_field:'EquipmentName',list_display_field:"LARCName"},
    created_by_field:{name:"CreatedBy",display:'Created By','type':'fetcher',op_not_allowed:['add','edit'],read_from_api:"User/Display_name/",ref_field:'UserId',display_field:'DisplayName'},
    last_modifiedby_field:{name:"LastModifiedBy",display:'Last Modified By','type':'text',op_not_allowed:['add','edit'],read_from_api:"User/Display_name/",ref_field:'UserId',display_field:'DisplayName'}
  }

}